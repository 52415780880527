// import React, { useState, useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import { useAuthProvider, useRedirect, useCheckAuth } from 'react-admin';
// import { useNavigate } from 'react-router-dom';
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Paper
// } from '@mui/material';
// import OtpInput from './OtpInput';
// import './LoginStyle.css';

// const Login = () => {
//   const authProvider = useAuthProvider();
//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const [otpSent, setOtpSent] = useState(false);
//   const { loading, authenticated } = useCheckAuth();
//   const redirect = useRedirect();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (authenticated) {
//       navigate('/users');
//     }
//   }, [authenticated, navigate]);

//   const onSubmit = ({ mobileNumber }) => {
//     console.log('Login mobileNumber:', mobileNumber);
//     authProvider
//       .getOtp({ mobileNumber })
//       .then(() => {
//         setOtpSent(true);
//       })
//       .catch((error) => {
//         console.error('Login error - Request failed:', error);
//       });
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <Container component="main" maxWidth="xs">
//       <Paper elevation={3} style={{ padding: '20px', marginTop: '30px' }}>
//         <Box display="flex" flexDirection="column" alignItems="center">
//           <Typography component="h1" variant="h5" style={{ marginBottom: '20px', color: '#3f51b5' }}>
//             Admin Login
//           </Typography>
//           {!otpSent ? (
//             <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 fullWidth
//                 label="Mobile Number"
//                 {...register('mobileNumber', { required: true })}
//                 error={!!errors.mobileNumber}
//                 helperText={errors.mobileNumber && "This field is required"}
//               />
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 style={{ marginTop: '20px' }}
//               >
//                 Request OTP
//               </Button>
//             </form>
//           ) : (
//             <OtpInput />
//           )}
//         </Box>
//       </Paper>
//     </Container>
//   );
// };

// export default Login;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthProvider, useRedirect, useCheckAuth } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper
} from '@mui/material';
import OtpInput from './OtpInput';
import './LoginStyle.css';

const Login = () => {
  const authProvider = useAuthProvider();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [otpSent, setOtpSent] = useState(false);
  const { loading, authenticated } = useCheckAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) {
      navigate('/users');
    }
  }, [authenticated, navigate]);

  const onSubmit = async ({ mobileNumber }) => {
    try {
      await authProvider.getOtp({ mobileNumber });
      setOtpSent(true);
    } catch (error) {
      console.error('Login error - Request failed:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} className="login-paper">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography component="h1" variant="h5" className="login-title">
            Admin Login
          </Typography>
          {!otpSent ? (
            <form onSubmit={handleSubmit(onSubmit)} className="login-form">
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Mobile Number"
                {...register('mobileNumber', { required: true })}
                error={!!errors.mobileNumber}
                helperText={errors.mobileNumber && "This field is required"}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="login-button"
              >
                Request OTP
              </Button>
            </form>
          ) : (
            <OtpInput />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;

