import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import Box from '@mui/material/Box';


const ScrapDuloAppBar = () => (
    <AppBar color="primary">
        <TitlePortal />
        <Box flex="0" />
        <h3>ScrapDulo Admin Portal</h3>
        <Box flex="1" />
    </AppBar>
);


export default ScrapDuloAppBar;