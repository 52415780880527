// import * as React from "react";
// import { Button, useRefresh } from "react-admin";

// const ActivateDealerButton = ({ record }) => {
//   const refresh = useRefresh();

//   const handleActivateDealer = () => {
//     if (record) {
//       const apiUrl = `http://localhost:8082/admin/user/dealer/activate/${record.id}`;
//       const token = localStorage.getItem('token');

//       // Make a PUT request using fetch
//       fetch(apiUrl, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `${token}`,
//         },
//       })
//         .then(response => {
//           if (response.ok) {
//             // Alert on success
//             alert('Dealer Activation Successful!');
//             // Trigger a refresh after successful action
//             refresh();
//           } else {
//             // Handle other response statuses as needed
//             console.error('Error making PUT request:', response.statusText);
//           }
//           return response.json();
//         })
//         .then(data => {
//           // Handle the response data as needed
//           console.log('PUT request successful:', data);
//         })
//         .catch(error => {
//           console.error('Error making PUT request:', error);
//         });
//     }
//   };

//   return (
//     <Button label="Activate Dealer" onClick={handleActivateDealer} />
//   );
// };

// export default ActivateDealerButton;
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import React from "react";
import { Button, useRefresh } from "react-admin";

const ActivateDealerButton = ({ record }) => {
  const refresh = useRefresh();

  const handleActivateDealer = async () => {
    if (!record) return;

    const apiUrl = `https://api.scrapdulo.com/admin/user/dealer/activate/${record.id}`;
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      });

      if (!response.ok) {
        console.error('Error making PUT request:', response.statusText);
        return;
      }

      const data = await response.json();
      console.log('PUT request successful:', data);

      alert('Dealer Activation Successful!');
      refresh();
    } catch (error) {
      console.error('Error making PUT request:', error);
    }
  };

  return <Button label="Activate Dealer" onClick={handleActivateDealer} />;
};

export default ActivateDealerButton;
