// // src/authProvider.js
// import { fetchUtils } from 'react-admin';

// // const apiUrl = 'https://api.scrapdulo.com'; // Replace with your backend API URL

// const apiUrl = 'http://localhost:8082'; // Replace with your backend API URL

// const authProvider = {
//   getOtp: ({ mobileNumber }) => {
//     const otpUrl = `${apiUrl}/admin/otp/auth/getOtp?username=${encodeURIComponent(mobileNumber)}`;

//     // return fetchUtils.fetchJson(otpUrl).then(({ json }) => {
//     //   if (json.success) {
//     //     // OTP request successful, return a promise
//     //     return Promise.resolve();
//     //   } else {
//     //     // OTP request failed, handle accordingly
//     //     return Promise.reject('Failed to request OTP');
//     //   }
//     // });

//     return fetchUtils.fetchJson(otpUrl)
//       .then(({ json }) => {
//         if (json.responseCode === 200 && json.responseMessage === 'OK') {
//           // OTP request successful, return the expected structure
//         //   return Promise.resolve({ redirectTo: '/dashboard' }); // replace '/dashboard' with the desired redirect path
//         return Promise.resolve();
//         } else {
//           // OTP request failed, reject the Promise with an error message
//           return Promise.reject('Failed to request OTP');
//         }
//       })
//       .catch((error) => {
//         // Handle other errors, e.g., network issues
//         console.error('Login error:', error);
//         return Promise.reject('Failed to request OTP');
//       });
      
//   },
  
//   registerUser: ({ email, mobile, referralCode, countryCode, otp }) => {
//     const registerUrl = `${apiUrl}/admin/user/register`;
    
    
//     // Create a Headers object
//     const headers = new Headers();
//     headers.set('Content-Type', 'application/json');

//     // Set the headers in the requestOptions
//     const requestOptions = {
//         headers: headers,
//         body: JSON.stringify({
//           email,
//           mobile,
//           referralCode,
//           countryCode,
//           otp,
//         }),
//       };
  
//       // Set the method property after creating the requestOptions
//       requestOptions.method = 'POST';

//     return fetchUtils.fetchJson(registerUrl, requestOptions)
//       .then(({ json }) => {
//         if (json.responseCode === 200 && json.responseMessage === 'OK') {
//           // Registration successful, save the token to localStorage
//           localStorage.setItem('token', json.token);
//           return Promise.resolve();
//         } else {
//           // Registration failed, reject the Promise with an error message
//           return Promise.reject('Failed to register user');
//         }
//       })
//       .catch((error) => {
//         console.error('Registration error:', error);
//         return Promise.reject('Failed to register user');
//       });
//   },


//   checkAuth: () => {
//     // Check if the user is authenticated, e.g., by verifying the presence of a token
//     return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
//   },

//   // Other authProvider methods...

//   // Optional: implement a custom logout method if needed
//   logout: () => {
//     localStorage.removeItem('token');
//     return Promise.resolve();
//   },
// };

// export default authProvider;

//////////////////////////////////////////////////////////////////////////////////////////////////////////

import { fetchUtils } from 'react-admin';

const apiUrl = 'https://api.scrapdulo.com'; // Replace with your backend API URL

const authProvider = {
  getOtp: ({ mobileNumber }) => {
    const otpUrl = `${apiUrl}/admin/otp/auth/getOtp?username=${encodeURIComponent(mobileNumber)}`;

    return fetchUtils.fetchJson(otpUrl)
      .then(({ json }) => {
        if (json.responseCode === 200 && json.responseMessage === 'OK') {
          return Promise.resolve();
        } else {
          return Promise.reject('Failed to request OTP');
        }
      })
      .catch((error) => {
        console.error('Login error:', error);
        return Promise.reject('Failed to request OTP');
      });
  },
  
  registerUser: ({ email, mobile, referralCode, countryCode, otp }) => {
    const registerUrl = `${apiUrl}/admin/user/register`;

    const headers = new Headers();
    headers.set('Content-Type', 'application/json');

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        email,
        mobile,
        referralCode,
        countryCode,
        otp,
      }),
    };

    return fetchUtils.fetchJson(registerUrl, requestOptions)
      .then(({ json }) => {
        if (json.responseCode === 200 && json.responseMessage === 'OK') {
          localStorage.setItem('token', json.token);
          return Promise.resolve();
        } else {
          return Promise.reject('Failed to register user');
        }
      })
      .catch((error) => {
        console.error('Registration error:', error);
        return Promise.reject('Failed to register user');
      });
  },

  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },

  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
};

export default authProvider;
