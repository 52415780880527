// import * as React from "react";
// import {
//   Button,
//   useRecordContext,
//   useRefresh,
// } from "react-admin";
// import axios from 'axios';

// const RedeemButton = () => {
//   const record = useRecordContext();
//   const refresh = useRefresh();

//   // State for managing the modal
//   const [isModalOpen, setIsModalOpen] = React.useState(false);
//   const [transactionId, setTransactionId] = React.useState('');
//   const [transactionAmount, setTransactionAmount] = React.useState('');
//   const [isTransactionIdValid, setIsTransactionIdValid] = React.useState(false);

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     setTransactionId(value);

//     // Validate the transaction ID
//     const isValid = value.trim().length >= 10;
//     setIsTransactionIdValid(isValid);
//   };


//   const handleAmountChange = (event) => {
//     setTransactionAmount(event.target.value);
//   };

//   const handleRedeem = () => {
//     // Open the modal when the Redeem button is clicked
//     handleOpenModal();
//   };

//   const handleConfirmRedeem = () => {
//     const amount = parseFloat(transactionAmount);
//     if (isNaN(amount)) {
//       alert('Please enter a valid transaction amount.');
//       return;
//     }

//     if (record && isTransactionIdValid) {
//       const apiUrl = `http://localhost:8082/admin/wallet/redeems/${record.id}/redeem`;
//       const token = localStorage.getItem('token');

//       // Make a PUT request using fetch
//       fetch(apiUrl, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `${token}`,
//         },
//         // Include the transactionId in the request body
//         body: JSON.stringify({ transactionId,transactionAmount: amount }),
//       })
//         .then(response => {
//           if (response.ok) {
//             // Alert on success
//             alert('Redeem Successful!');
//             // Trigger a refresh after successful action
//             refresh();
//           } else {
//             // Handle other response statuses as needed
//             console.error('Error making PUT request:', response.statusText);
//           }
//           return response.json();
//         })
//         .then(data => {
//           // Handle the response data as needed
//           console.log('PUT request successful:', data);
//         })
//         .catch(error => {
//           console.error('Error making PUT request:', error);
//         });

//       // Close the modal after confirming the Redeem action
//       handleCloseModal();
//     }
//   };

//   return (
//     <>
//       <Button label="Redeem" onClick={handleRedeem} />
      
//       {/* Modal for entering the transaction ID */}
//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={handleCloseModal}>&times;</span>
//             <label>Enter Transaction ID:</label>
//             <input type="text" value={transactionId} onChange={handleInputChange} />
//             {!isTransactionIdValid && (
//               <p className="error-message">Transaction ID must be at least 10 characters long.</p>
//             )}
//             <label>Enter Transaction Amount:</label>
//             <input type="number" step="0.01" value={transactionAmount} onChange={handleAmountChange} />
//             <button disabled={!isTransactionIdValid} onClick={handleConfirmRedeem}>Confirm Redeem</button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default RedeemButton;
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// import React, { useState } from "react";
// import { Button, useRecordContext, useRefresh } from "react-admin";
// import './ModalStyles.css';  // Import the CSS file

// const RedeemButton = () => {
//   const record = useRecordContext();
//   const refresh = useRefresh();

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [transactionId, setTransactionId] = useState('');
//   const [transactionAmount, setTransactionAmount] = useState('');
//   const [isTransactionIdValid, setIsTransactionIdValid] = useState(false);

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setTransactionId('');
//     setTransactionAmount('');
//     setIsTransactionIdValid(false);
//   };

//   const handleInputChange = (event) => {
//     const value = event.target.value;
//     setTransactionId(value);
//     setIsTransactionIdValid(value.trim().length >= 10);
//   };

//   const handleAmountChange = (event) => {
//     setTransactionAmount(event.target.value);
//   };

//   const handleConfirmRedeem = () => {
//     const amount = parseFloat(transactionAmount);
//     if (isNaN(amount)) {
//       alert('Please enter a valid transaction amount.');
//       return;
//     }

//     if (record && isTransactionIdValid) {
//       const apiUrl = `https://api.scrapdulo.com/admin/wallet/redeems/${record.id}/redeem`;
//       const token = localStorage.getItem('token');

//       fetch(apiUrl, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `${token}`,
//         },
//         body: JSON.stringify({ transactionId, transactionAmount: amount }),
//       })
//         .then(response => {
//           if (response.ok) {
//             alert('Redeem Successful!');
//             refresh();
//           } else {
//             console.error('Error making PUT request:', response.statusText);
//             alert('Redeem failed. Please try again.');
//           }
//           return response.json();
//         })
//         .then(data => {
//           console.log('PUT request successful:', data);
//         })
//         .catch(error => {
//           console.error('Error making PUT request:', error);
//           alert('Redeem failed. Please try again.');
//         });

//       handleCloseModal();
//     }
//   };

//   return (
//     <>
//       <Button label="Redeem" onClick={handleOpenModal} />
      
//       {isModalOpen && (
//         <div className="modal">
//           <div className="modal-content">
//             <span className="close" onClick={handleCloseModal}>&times;</span>
//             <label>Enter Transaction ID:</label>
//             <input type="text" value={transactionId} onChange={handleInputChange} />
//             {!isTransactionIdValid && (
//               <p className="error-message">Transaction ID must be at least 10 characters long.</p>
//             )}
//             <label>Enter Transaction Amount:</label>
//             <input type="number" step="0.01" value={transactionAmount} onChange={handleAmountChange} />
//             <button disabled={!isTransactionIdValid} onClick={handleConfirmRedeem}>Confirm Redeem</button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default RedeemButton;






import React, { useState } from "react";
import { Button, useRecordContext, useRefresh } from "react-admin";
import './ModalStyles.css';  // Import the CSS file

const RedeemButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');
  const [currency, setCurrency] = useState('');
  const [redeemDate, setRedeemDate] = useState('');
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [isTransactionIdValid, setIsTransactionIdValid] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setTransactionId('');
    setTransactionAmount('');
    setCurrency('');
    setRedeemDate('');
    setStatus('');
    setDescription('');
    setIsTransactionIdValid(false);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setTransactionId(value);
    setIsTransactionIdValid(value.trim().length >= 10);
  };

  const handleAmountChange = (event) => {
    setTransactionAmount(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleRedeemDateChange = (event) => {
    setRedeemDate(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleConfirmRedeem = () => {
    const amount = parseFloat(transactionAmount);
    if (isNaN(amount)) {
      alert('Please enter a valid transaction amount.');
      return;
    }

    if (record && isTransactionIdValid) {
      const apiUrl = `https://api.scrapdulo.com/admin/wallet/redeems/${record.id}/redeem`;
      const token = localStorage.getItem('token');

      const requestBody = {
        transactionId,
        transactionAmount: amount,
        currency,
        redeemDate,
        status,
        description
      };

      fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then(response => {
          if (response.ok) {
            alert('Redeem Successful!');
            refresh();
          } else {
            console.error('Error making PUT request:', response.statusText);
            alert('Redeem failed. Please try again.');
          }
          return response.json();
        })
        .then(data => {
          console.log('PUT request successful:', data);
        })
        .catch(error => {
          console.error('Error making PUT request:', error);
          alert('Redeem failed. Please try again.');
        });

      handleCloseModal();
    }
  };

  return (
    <>
      <Button label="Redeem" onClick={handleOpenModal} />
      
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <label>Enter Transaction ID:</label>
            <input type="text" value={transactionId} onChange={handleInputChange} />
            {!isTransactionIdValid && (
              <p className="error-message">Transaction ID must be at least 10 characters long.</p>
            )}
            <label>Enter Transaction Amount:</label>
            <input type="number" step="0.01" value={transactionAmount} onChange={handleAmountChange} />
            <label>Enter Currency:</label>
            <input type="text" value={currency} onChange={handleCurrencyChange} />
            <label>Select Redeem Date:</label>
            <input type="datetime-local" value={redeemDate} onChange={handleRedeemDateChange} />
            <label>Enter Status:</label>
            <input type="text" value={status} onChange={handleStatusChange} />
            <label>Enter Description:</label>
            <textarea value={description} onChange={handleDescriptionChange} />
            <button disabled={!isTransactionIdValid} onClick={handleConfirmRedeem}>Confirm Redeem</button>
          </div>
        </div>
      )}
    </>
  );
};

export default RedeemButton;
