// // roleDataProvider.js
// import { fetchUtils } from 'react-admin';

// const apiUrl = 'http://localhost:8082'; // Replace with your Spring Boot API URL

// const roleDataProvider = {
//   ...fetchUtils,
//   getAccessToken: () => localStorage.getItem('token'),

//   // Override the fetchJson method to include the authentication token in the headers
//   fetchJson: (url, options = {}) => {
//     const token = roleDataProvider.getAccessToken();
//     if (token) {
//       options.headers = options.headers || new Headers();
//       options.headers.set('Authorization', `Bearer ${token}`);
//     }

//     return fetchUtils.fetchJson(url, options);
//   },

//   // Override the getList method to handle custom URLs
//   getList: (resource, params) => {
//     const { customUrl } = params.options || {}; // Access options from params

//     if (customUrl) {
//       return roleDataProvider.fetchJson(customUrl, { method: 'GET' });
//     }

//     // Default behavior, use the standard resource URL
//     const url = `${apiUrl}/${resource}`;
//     return roleDataProvider.fetchJson(url);
//   },

//   // Add other data provider methods as needed
// };

// export default roleDataProvider;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { fetchUtils } from 'react-admin';

const apiUrl = 'https://api.scrapdulo.com'; // Replace with your Spring Boot API URL

const roleDataProvider = {
  ...fetchUtils,
  getAccessToken: () => localStorage.getItem('token'),

  fetchJson: (url, options = {}) => {
    const token = roleDataProvider.getAccessToken();
    if (token) {
      options.headers = options.headers || new Headers();
      options.headers.set('Authorization', `Bearer ${token}`);
    }

    // Add error handling for fetch
    return fetchUtils.fetchJson(url, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
      })
      .catch(error => {
        console.error('Fetch error:', error);
        throw error; // rethrow error to be handled by the caller
      });
  },

  getList: (resource, params) => {
    const { customUrl } = params.options || {};

    if (customUrl) {
      return roleDataProvider.fetchJson(customUrl, { method: 'GET' });
    }

    const url = `${apiUrl}/${resource}`;
    return roleDataProvider.fetchJson(url);
  },

  // Add POST, PUT, DELETE methods
  create: (resource, params) => {
    const url = `${apiUrl}/${resource}`;
    return roleDataProvider.fetchJson(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    });
  },

  update: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return roleDataProvider.fetchJson(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    });
  },

  delete: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    return roleDataProvider.fetchJson(url, {
      method: 'DELETE',
    });
  },

  // Add other methods as needed
};

export default roleDataProvider;
