// import React, { useState } from 'react';
// import { useAuthProvider, useRedirect } from 'react-admin';
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Paper
// } from '@mui/material';

// const OtpInput = () => {
//   const authProvider = useAuthProvider();
//   const redirect = useRedirect(); // Get the redirect function from react-admin
//   const [otp, setOtp] = useState('');
//   const [mobileNumber, setMobileNumber] = useState('');
//   const [countryCode, setCountryCode] = useState('');
//   const [otpError, setOtpError] = useState('');
//   const [otpVerified, setOtpVerified] = useState(false);

//   const handleInputChange = (e) => {
//     setOtp(e.target.value);
//     // Clear the error message when the user starts typing again
//     if (otpError) {
//       setOtpError('');
//     }
//   };

//   const handleMobileChange = (e) => {
//     setMobileNumber(e.target.value);
//   };

//   const handleCountryCodeChange = (e) => {
//     setCountryCode(e.target.value);
//   };

//   const onSubmit = (e) => {
//     e.preventDefault();

//     // Validate OTP
//     if (!otp) {
//       setOtpError('This field is required');
//       return;
//     }

//     // Validate mobile number and country code
//     if (!mobileNumber || !countryCode) {
//       setOtpError('Mobile number and country code are required');
//       return;
//     }

//     // Call registerUser with the required information
//     authProvider
//       .registerUser({
//         email: null, // Pass the email if required
//         mobile: mobileNumber,
//         countryCode: countryCode,
//         referralCode: null, // Pass the referral code if required
//         otp: otp,
//       })
//       .then(() => {
//         // Registration successful, you can redirect or handle accordingly
//         console.log('Registration Successful');
//         redirect('/users'); // Redirect to the Users list
//         // or redirect('/dashboard'); // Redirect to the Dashboard
//       })
//       .catch((error) => {
//         console.error('Registration error:', error);
//         // Handle error, e.g., display an error message to the user
//         setOtpError('Failed to register user');
//       });
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Paper elevation={3} style={{ padding: '20px', marginTop: '30px' }}>
//         <Box display="flex" flexDirection="column" alignItems="center">
//           <Typography component="h1" variant="h5" style={{ marginBottom: '20px', color: '#3f51b5' }}>
//             Enter OTP
//           </Typography>
//           <form onSubmit={onSubmit} style={{ width: '100%' }}>
//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="Mobile Number"
//               value={mobileNumber}
//               onChange={handleMobileChange}
//               error={!!otpError && !mobileNumber}
//               helperText={!mobileNumber ? otpError : ''}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="Country Code"
//               value={countryCode}
//               onChange={handleCountryCodeChange}
//               error={!!otpError && !countryCode}
//               helperText={!countryCode ? otpError : ''}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               fullWidth
//               label="OTP"
//               value={otp}
//               onChange={handleInputChange}
//               error={!!otpError}
//               helperText={otpError}
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               color="primary"
//               style={{ marginTop: '20px' }}
//             >
//               Login
//             </Button>
//           </form>
//         </Box>
//       </Paper>
//     </Container>
//   );
// };

// export default OtpInput;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


import React, { useState } from 'react';
import { useAuthProvider, useRedirect } from 'react-admin';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper
} from '@mui/material';

const OtpInput = () => {
  const authProvider = useAuthProvider();
  const redirect = useRedirect(); // Get the redirect function from react-admin
  const [otp, setOtp] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [otpError, setOtpError] = useState('');

  const handleInputChange = (e) => {
    setOtp(e.target.value);
    if (otpError) {
      setOtpError('');
    }
  };

  const handleMobileChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!otp) {
      setOtpError('OTP is required');
      return;
    }

    if (!mobileNumber || !countryCode) {
      setOtpError('Mobile number and country code are required');
      return;
    }

    authProvider
      .registerUser({
        email: null,
        mobile: mobileNumber,
        countryCode: countryCode,
        referralCode: null,
        otp: otp,
      })
      .then(() => {
        console.log('Registration Successful');
        redirect('/users');
      })
      .catch((error) => {
        console.error('Registration error:', error);
        setOtpError('Failed to register user');
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '30px' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography component="h1" variant="h5" style={{ marginBottom: '20px', color: '#3f51b5' }}>
            Enter OTP
          </Typography>
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Mobile Number"
              value={mobileNumber}
              onChange={handleMobileChange}
              error={!!otpError && !mobileNumber}
              helperText={!mobileNumber && otpError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Country Code"
              value={countryCode}
              onChange={handleCountryCodeChange}
              error={!!otpError && !countryCode}
              helperText={!countryCode && otpError}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="OTP"
              value={otp}
              onChange={handleInputChange}
              error={!!otpError}
              helperText={otpError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
            >
              Login
            </Button>
          </form>
        </Box>
      </Paper>
    </Container>
  );
};

export default OtpInput;
