
// import authProvider from './authProvider';
import * as React from "react";
import { Admin, Resource, ListGuesser ,combineDataProviders, List, 
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput, 
  Menu, // Import Menu from react-admin
  MenuItemLink,
  EditGuesser,
  ShowGuesser,
  EditButton,
  Button,
  useRefresh,
  useRecordContext,
  useShowController,
  Edit, SimpleForm, NumberInput, DateInput, DateField, NumberField, Show, SimpleShowLayout,
  // ImageField
} from 'react-admin';

import { format } from 'date-fns';

import axios from 'axios';


import UsersIcon from '@mui/icons-material/PeopleAltRounded';
import RolesIcon from '@mui/icons-material/RollerShadesRounded';
import WalletIcon from '@mui/icons-material/WalletRounded';
import RedeemIcon from '@mui/icons-material/RedeemRounded';

// import { Layout } from 'react-admin';
import  ScrapDuloAppBar  from "./components/scrapDuloAppBar";
import Dashboard from './Dashboard';
import RedeemButton from "./components/RedeemButton";
import ActivateDealerButton from "./components/ActivateDealerButton";

import jsonServerProvider from 'ra-data-json-server';
import simpleRestProvider from 'ra-data-simple-rest';
import LoginViaOTP from './LoginViaOTP';
import Form from './Form';

// import dataProvider_ from './components/dataProvider';
import authProvider from './components/authProvider';


import roleDataProvider from './components/roleDataProvider';
import { fetchUtils } from 'react-admin';


import Login from './components/Login';

import CustomLoginPage from './components/CustomLoginPage';

import Registration from "./components/Registration";
import { MyLayout } from "./components/scrpDuloLayout";
import { format as formatTz, toZonedTime } from 'date-fns-tz';
import { parseISO } from 'date-fns';


// Custom TimeField Component

// Custom TimeField Component
const TimeField = ({ value }) => {
  if (!value) {
    return <span>Invalid Date</span>;
  }

  let date;
  try {
    date = parseISO(value);
  } catch (error) {
    console.error('Error parsing date:', error);
    return <span>Invalid Date</span>;
  }

  const timeZone = 'Asia/Kolkata'; // Adjust timezone as needed
  const zonedDate = toZonedTime(date, timeZone);
  const formattedDate = formatTz(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });

  return <span>{formattedDate}</span>;
};


const apiUrl = 'https://api.scrapdulo.com/admin'; // Replace with your Spring Boot API URL


// const apiUrl = 'http://localhost:8082'; // Replace with your Spring Boot API URL
const httpClient = (url, options = {}) => {
  const token=localStorage.getItem('token');
  if (token) {
    options.user = {
      authenticated: true,
      token: token
  };
  }
  return fetchUtils.fetchJson(url, options);
};
const dataProviderWallet = jsonServerProvider('https://api.scrapdulo.com/admin/wallet',httpClient);
const dataProviderWalletGold = jsonServerProvider('https://api.scrapdulo.com/admin/wallet/gold',httpClient);

const dataProviderUser = jsonServerProvider('https://api.scrapdulo.com/admin/user',httpClient);

const dataProviderRole = jsonServerProvider('https://api.scrapdulo.com/admin/role',httpClient);

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case 'wallets':
    case 'redeems':
        return dataProviderWallet;
    case 'redeems-gold':
        return dataProviderWalletGold;
    case 'users':
      return dataProviderUser;
    case 'dealerRequests':
        return dataProviderUser;
    case 'roles':
        return dataProviderRole;
    default:
        throw new Error(`Unknown resource: ${resource}`);
  }
});


// Custom Filter Component
const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by UUID" source="uuid" />
    <TextInput label="Search by Email" source="email" />
    <TextInput label="Search by Country Code" source="countryCode" />
    <TextInput label="Search by Referral Code" source="referralCode" />
    <TextInput label="Search by Status" source="status" />
    <TextInput label="Search by Mode" source="mode" />
    <TextInput label="Search by Rating" source="rating" />
    <TextInput label="Search by Created On" source="createdOn" />
    <TextInput label="Search by Last Seen" source="lastSeen" />
    <TextInput label="Search by Area" source="area" />
    <TextInput label="Search by Min Capacity" source="minCapacity" />
    <TextInput label="Search by Max Capacity" source="maxCapacity" />
  </Filter>
);

const DealerRequestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by UUID" source="uuid" />
    <TextInput label="Search by Email" source="email" />
    <TextInput label="Search by Country Code" source="countryCode" />
    <TextInput label="Search by Referral Code" source="referralCode" />
    <TextInput label="Search by Status" source="status" />
    <TextInput label="Search by Mode" source="mode" />
    <TextInput label="Search by Rating" source="rating" />
    <TextInput label="Search by Created On" source="createdOn" />
    <TextInput label="Search by Last Seen" source="lastSeen" />
    <TextInput label="Search by Area" source="area" />
    <TextInput label="Search by Min Capacity" source="minCapacity" />
    <TextInput label="Search by Max Capacity" source="maxCapacity" />
  </Filter>
);


const WalletsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by Name" source="name" />
    <TextInput label="Search by Primary Balance" source="primaryBalance" />
    <TextInput label="Search by Currency" source="currency" />
    <TextInput label="Search by Account Number" source="accountNumber" />
    <TextInput label="Search by Bank Name" source="bankName" />
    <TextInput label="Search by IFSC Code" source="ifsccode" />
    <TextInput label="Search by Secondary Balance" source="secondaryBalance" />
  </Filter>
);


const RedeemsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by Name" source="name" />
    <TextInput label="Search by Primary Balance" source="primaryBalance" />
    <TextInput label="Search by Currency" source="currency" />
    <TextInput label="Search by Account Number" source="accountNumber" />
    <TextInput label="Search by Bank Name" source="bankName" />
    <TextInput label="Search by IFSC Code" source="ifsccode" />
    <TextInput label="Search by Secondary Balance" source="secondaryBalance" />
  </Filter>
);

const RedeemsGoldFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by Name" source="name" />
    <TextInput label="Search by Primary Balance" source="primaryBalance" />
    <TextInput label="Search by Currency" source="currency" />
    <TextInput label="Search by Account Number" source="accountNumber" />
    <TextInput label="Search by Bank Name" source="bankName" />
    <TextInput label="Search by IFSC Code" source="ifsccode" />
    <TextInput label="Search by Secondary Balance" source="secondaryBalance" />
  </Filter>
);



const RolesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by Name" source="name" />
  </Filter>
);

const CustomButton = () => {
  const handleClick = () => {
    alert('Hello!');
  };

  return (
    <MenuItemLink to="/" onClick={handleClick} primaryText="Custom Button" />
  );
};

const CustomMenu = (props) => (
  <Menu {...props}>
    <CustomButton />
  </Menu>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="uuid" />
      <NumberInput source="mobile" />
      <TextInput source="countryCode" />
      <DateInput source="createdOn" />
      <TextInput source="mode" />
      <TextInput source="status" />
      <TextInput source="rating" />
      <TextInput source="referralCode" />
      <TextInput source="minCapacity" />
      <TextInput source="maxCapacity" />
      <TextInput source="area" />
    </SimpleForm>
  </Edit>
);

const App = () => 
  
  <Admin 
  loginPage={CustomLoginPage} 
  dataProvider={dataProvider} 
  authProvider={authProvider}
  
  
  // layout={MyLayout}
  
  // https://marmelab.com/react-admin/Layout.html => For cutomized layout
  // layout={ScrapDuloAppBar} // Use Layout component
  // appLayout={ScrapDuloAppBar} // Use CustomMenu as the appLayout
  >
    {/* Add resources here */}
    <Resource name="users" list={UserList} icon={UsersIcon} show={UserShow} />
    <Resource name="dealerRequests" list={DealerRequestList} show={UserRequestShow}/>
    <Resource name="redeems" list={RedeemList} icon={RedeemIcon}  show={RedeemShow} />
    <Resource name="redeemsGold" list={RedeemGoldList} icon={RedeemIcon}  show={RedeemGoldShow} />
    <Resource name="wallets" list={WalletList} icon={WalletIcon}/>
    <Resource name="roles" list={ListGuesser} />
    {/* <Resource name="users" list={ListGuesser} /> */}
  </Admin>


export const UserShow = () => (
  <Show>
      <SimpleShowLayout>
          <TextField source="id" disabled/>
          <TextField source="uuid" />
          <TextField source="mobile" />
          <TextField source="countryCode" />
          <DateField source="createdOn" />
          <TextField source="mode" />
          <TextField source="idProof" />
          <TextField source="addressProof" />
          <TextField source="status" />
          <TextField source="rating" />
          <TextField source="referralCode" />
          <TextField source="minCapacity" />
          <TextField source="maxCapacity" />
          <TextField source="area" />
      </SimpleShowLayout>
  </Show>
);


const UserList = (props) => (
  <List {...props} filters={<UsersFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" disabled />
      <TextField source="uuid" />
      <TextField source="email" />
      <TextField source="countryCode" />
      <TextField source="status" />
      <TextField source="mode" />
      <TextField source="rating" />
      <TextField source="referralCode" />
      <DateField source="createdOn" />
      <DateField source="lastSeen" />
      <TextField source="area" />
      <TextField source="minCapacity" />
      <TextField source="maxCapacity" />
      {/* Add other fields as needed */}
    </Datagrid>
  </List>
);

// export const Edit = () => (
//   <Edit>
//     <SimpleForm>
//       <TextInput source="id" InputProps={{ disabled: true }} />
//       <ReferenceInput source="userId" reference="users" link="show" />
//       <TextInput source="id" />
//       <TextInput source="status" />
//     </SimpleForm>
//   </Edit>
// );


const CustomDateCell = ({ record, source }) => {
  console.log('CustomDateCell - Record:', record);
  console.log('CustomDateCell - Source:', source);

  if (!record) {
    return <span>Loading...</span>;
  }

  const value = record[source];
  console.log('CustomDateCell - Value:', value);

  return <TimeField value={value} />;
};

const DealerRequestList = (props) => (
  <List {...props} filters={<DealerRequestFilter />}>
    <Datagrid rowClick="show">
    {/* <Datagrid onClick={(id, record) => ({ id, record })} > */}
      <TextField source="id" />
      <TextField source="uuid" />
      {/* <TextField source="email" /> */}
      <TextField source="countryCode" />
      <TextField source="mode" />
      {/* <TextField source="rating" /> */}
      <DateField  source="createdOn" />
      <DateField source="lastSeen" />
      <TextField source="area" />
      <TextField source="minCapacity" />
      <TextField source="maxCapacity" />
      <TextField source="status" />
      <TextField source="idProof" />
      <TextField source="addressProof" />
      {/* <TextField source="referralCode" /> */}
      {/* <EditButton /> */}
      {/* Add other fields as needed */}
    </Datagrid>
  </List>
);

// Custom Image Field Component
const ImageField = ({ record = {}, source }) => {
  const url = record[source];
  if (!url) {
    return <span>No Image</span>;
  }

  return (
    <div>
      <img src={url} alt="Image" style={{ maxWidth: '500px', maxHeight: '500px' }} />
      {/* <div>{url}</div> */}
    </div>
  );
};
const UserRequestShow = (props) => {
  const { record } = useShowController(props);

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ActivateDealerButton record={record} />
        <TextField source="id" disabled />
        <TextField source="uuid" />
        <TextField source="email" />
        <TextField source="countryCode"/>
        <TextField source="status" />
        <TextField source="mode" />
        <TextField source="rating" />
        <TextField source="referralCode" />
        <DateField source="createdOn" />
        <DateField source="lastSeen" />
        <TextField source="area" />
        <TextField source="minCapacity" />
        <TextField source="maxCapacity" />
        <ImageField source="idProof" record={record}/>
        <ImageField source="addressProof" record={record}/>
      </SimpleShowLayout>
    </Show>
  );
};

// const RedeemButton = ({ record }) => {
//   const handleRedeem = () => {
//     // Perform your API call here
//     // You can access the record data if needed (e.g., record.id)
//     alert(`Redeem button clicked for ID: ${record ? record.id : 'No ID'}`);
//   };

//   return <Button label="Redeem" onClick={handleRedeem} />;
// };

// const RedeemButton = () => {
//   const record = useRecordContext();
//   const refresh = useRefresh(); // Access the refresh function

  
//   // State for managing the modal
//   const [isModalOpen, setIsModalOpen] = React.useState(false);
//   const [transactionId, setTransactionId] = React.useState('');

//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleInputChange = (event) => {
//     setTransactionId(event.target.value);
//   };

//   const handleRedeem = () => {
//     // Open the modal when the Redeem button is clicked
//     handleOpenModal();
//   };

  

//   const handleRedeem = () => {
//     // Perform your API call here
//     // You can access the record data if needed (e.g., record.id)
//     // alert(`Redeem button clicked for ID: ${record ? record.id : 'No ID'}`);

//     if (record) {
//       const apiUrl = `http://localhost:8082/admin/wallet/redeems/${record.id}/redeem`;
//       const token = localStorage.getItem('token');
//       // Make a PUT request using fetch
//       fetch(apiUrl, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `${token}`,
//           // Add any other headers you need, such as authorization headers
//         },
//         // If you need to send data in the request body, add a body property
//         // body: JSON.stringify({ /* your data here */ }),
//       })
//         .then(response => {
//           if (response.ok) {
//             // Alert on success
//             alert('Redeem Successful!');
//             // Trigger a refresh after successful action
//             refresh();
//           } else {
//             // Handle other response statuses as needed
//             console.error('Error making PUT request:', response.statusText);
//           }
//           return response.json();
//         })
//         .then(data => {
//           // Handle the response data as needed
//           console.log('PUT request successful:', data);
//         })
//         .catch(error => {
//           console.error('Error making PUT request:', error);
//         });

//       // Alternatively, you can use axios for the request
//       // axios.put(apiUrl, { /* your data here */ })
//       //   .then(response => {
//       //     console.log('PUT request successful:', response.data);
//       //   })
//       //   .catch(error => {
//       //     console.error('Error making PUT request:', error);
//       //   });
//     }

//   };

//   return <Button label="Redeem" onClick={handleRedeem} />;
// };


const RedeemList = (props) => (
  <List {...props} filters={<RedeemsFilter />}>
    <Datagrid onClick={(id, record) => ({ id, record })} >
      <TextField source="id" />
      <RedeemButton/>
      <TextField source="name" />
      <TextField source="primaryBalance" />
      <TextField source="currency" />
      <TextField source="fullName" />
      <TextField source="accountNumber" />
      <TextField source="bankName" />
      <TextField source="branchName" />
      <TextField source="ifsccode" />
      <TextField source="secondaryBalance" />
      {/* Add other fields as needed */}
    </Datagrid>
  </List>
);

const RedeemGoldList = (props) => (
  <List {...props} filters={<RedeemsGoldFilter />}>
    <Datagrid onClick={(id, record) => ({ id, record })} >
      <TextField source="id" />
      <RedeemButton/>
      <TextField source="name" />
      <TextField source="primaryBalance" />
      <TextField source="currency" />
      <TextField source="fullName" />
      <TextField source="accountNumber" />
      <TextField source="bankName" />
      <TextField source="branchName" />
      <TextField source="ifsccode" />
      <TextField source="secondaryBalance" />
      {/* Add other fields as needed */}
    </Datagrid>
  </List>
);

export const RedeemShow = () => (
  <Show>
      <SimpleShowLayout>
          <TextField source="id" disabled/>
          <TextField source="name" />
          <TextField source="primaryBalance" />
          <TextField source="currency" />
          <TextField source="fullName" />
          <TextField source="accountNumber" />
          <TextField source="bankName" />
          <TextField source="branchName" />
          <TextField source="ifsccode" />
          <TextField source="secondaryBalance" />
      </SimpleShowLayout>
  </Show>
);


export const RedeemGoldShow = () => (
  <Show>
      <SimpleShowLayout>
          <TextField source="id" disabled/>
          <TextField source="name" />
          <TextField source="primaryBalance" />
          <TextField source="currency" />
          <TextField source="fullName" />
          <TextField source="accountNumber" />
          <TextField source="bankName" />
          <TextField source="branchName" />
          <TextField source="ifsccode" />
          <TextField source="secondaryBalance" />
      </SimpleShowLayout>
  </Show>
);

const WalletList = (props) => (
  <List {...props} filters={<WalletsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="primaryBalance" />
      <TextField source="currency" />
      <TextField source="fullName" />
      <TextField source="accountNumber" />
      <TextField source="bankName" />
      <TextField source="branchName" />
      <TextField source="ifsccode" />
      <TextField source="secondaryBalance" />
      {/* Add other fields as needed */}
    </Datagrid>
  </List>
);

export default App;
